import { init, send } from '@emailjs/browser';
import { openEmailModal } from './modal';

class Email {
    #parentElement = document.querySelector('.contact-form');
    #messageSuccess = 'Your email was sent successfully! 😎';
    #messageFailure = 'Ooops. An error occurred. Please try again later. 😭';

    constructor() {
        this.#addUpload();
        this.#initListeners();
        init('0KLoI-clvoz3Nhu8M');
    }

    #resetInputFieldLabels = () => {
        document.getElementById('contact_form').reset();
        const fields = document.querySelectorAll('.form-control');
        fields.forEach((field) => this.#toggleInputFielLabels(field));
    };

    #sendEmail(data) {
        const templateParams = {
            name: data.name,
            email: data.email,
            subject: data.subject,
            message: data.message,
        };

        send('service_w366smi', 'template_30r8suj', templateParams).then(
            // Promise.reject('Page 404 not fould').then(
            () => {
                openEmailModal(this.#messageSuccess);
            },
            (error) => {
                openEmailModal(this.#messageFailure, error);
            }
        );
    }

    #addUpload() {
        this.#parentElement.addEventListener('submit', (e) => {
            e.preventDefault();
            const dataArr = [...new FormData(this.#parentElement)];
            const data = Object.fromEntries(dataArr);
            this.#resetInputFieldLabels();
            this.#sendEmail(data);
        });
    }

    #initListeners() {
        const fields = document.querySelectorAll('.form-control');
        fields.forEach((field) =>
            field.addEventListener('blur', this.#toggleInputFielLabels.bind(this, field))
        );
    }

    #toggleInputFielLabels(field) {
        const label = document.querySelector(`#label_${field.id}`);
        field.value !== '' ? (label.style.display = 'none') : (label.style.display = 'block');
    }
}

export default new Email();
