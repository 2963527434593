import getAnimation from './animationSelector';
import { closeModal } from './modal';

export default class PageTransition {
    #classMap = new Map();
    #currentPageMap = new Map();
    #animEndEventName = 'animationend';
    #isAnimating = false;
    #endCurrentPage = true;
    #endNextPage = false;
    #transitionNumber = 0;

    constructor(transitionNumber) {
        this.#transitionNumber = transitionNumber;
        const start = this.#getStartMenuItem();
        this.#activeMenuItem(start);
        this.#populateClassMap();
        this.#initMenuItems();
        this.#animate(start);
    }

    #getStartMenuItem = () => {
        const pageStart = this.#getActiveSectionName();
        const homePage = document.querySelectorAll('.nav-anim');
        const start = Array.from(homePage).find(
            (element) => element.getAttribute('href').slice(1) === pageStart
        );
        return start;
    };

    #activeMenuItem = (page) => {
        if (!page) return false;
        const menu = document.querySelectorAll('.nav-anim');
        menu.forEach((child) => child.classList.remove('active'));
        page.classList.toggle('active');
    };

    #populateClassMap = () => {
        const sections = document.querySelectorAll('.animated-section');
        sections.forEach((section) => {
            this.#classMap.set(section.dataset.id, [...section.classList]);
            if (section.dataset.id === this.#getActiveSectionName()) {
                section.classList.add('section-active');
                this.#currentPageMap.set('current', section.dataset.id);
            }
        });
    };

    #getActiveSectionName = () => {
        // if no active page then start with start page
        if (location.hash === '') {
            const start = document.querySelector('.start-page');
            location.hash = start.dataset.id;
            return location.hash.slice(1);
        } else {
            return location.hash.slice(1);
        }
    };

    #initAnimate = (item) => {
        if (this.#isAnimating) return false;
        this.#activeMenuItem(item);
        closeModal();
        this.#animate(item);
        location.hash = item.getAttribute('href');
    };

    #initMenuItems = () => {
        const navigation = document.querySelectorAll('.nav-anim');
        navigation.forEach((menuItem) => {
            menuItem.addEventListener('click', this.#initAnimate.bind(this, menuItem));
        });
    };

    #animateCurrentPage = (currentPage, nextPage) => {
        this.#endCurrentPage = true;
        if (this.#endNextPage) {
            this.#onEndAnimation(nextPage, currentPage);
            this.#endCurrentPage = false;
        }
    };

    #animateNextPage = (currentPage, nextPage) => {
        this.#endNextPage = true;
        if (this.#endCurrentPage) {
            this.#onEndAnimation(nextPage, currentPage);
            this.#endNextPage = false;
            this.#isAnimating = false;
        }
    };

    #onEndAnimation = (nextPage, currentPage) => {
        currentPage.classList = '';
        const currentPageOriginalClassList = this.#classMap.get(currentPage.dataset.id);
        currentPage.classList.add(...currentPageOriginalClassList);

        nextPage.classList = '';
        const nextPageOriginalClassList = this.#classMap.get(nextPage.dataset.id);
        nextPage.classList.add(...nextPageOriginalClassList);
        nextPage.classList.add('section-active');
    };

    #animate = (page) => {
        const [a, b] = getAnimation(this.#transitionNumber);
        const inClass = a.split(' ');
        const outClass = b.split(' ');

        let currentPageId = this.#currentPageMap.get('current');
        let gotoPage = page?.getAttribute('href').slice(1);
        let tempPageIndex = currentPageId;

        // Current page to be removed.
        const currentPage = document.querySelector('[data-id=' + `${currentPageId}` + ']');

        // NEXT PAGE
        currentPageId = gotoPage;

        // Check if the current page is same as the next page then do not do the animation
        // else reset the 'isAnimatiing' flag
        if (tempPageIndex != currentPageId) {
            this.#isAnimating = true;

            this.#currentPageMap.set('current', currentPageId);

            // Next page to be animated.
            const nextPage = document.querySelector('[data-id=' + `${currentPageId}` + ']');
            nextPage.classList.add('section-active');

            nextPage.scrollTop = 0;

            currentPage.classList.add(outClass);
            currentPage.addEventListener(
                this.#animEndEventName,
                this.#animateCurrentPage.bind(this, currentPage, nextPage),
                { once: true }
            );

            nextPage.classList.add(...inClass);
            nextPage.addEventListener(
                this.#animEndEventName,
                this.#animateNextPage.bind(this, currentPage, nextPage),
                { once: true }
            );
        } else {
            this.#isAnimating = false;
        }
    };
}
