export default function getAnimation(number) {
    switch (number) {
        case 0:
            return ['animated-section-moveFromTopFade', 'animated-section-rotateFoldBottom'];
        case 1:
            return [
                'animated-section-moveFromRight animated-section-ontop',
                'animated-section-scaleDown',
            ];
        case 2:
            return ['animated-section-moveFromTop', 'animated-section-moveToBottom'];
        case 3:
            return ['animated-section-moveFromTopFade', 'animated-section-moveToBottomFade'];
    }
}
