const modal = document.querySelector('.modal');
const overlay = document.querySelector('.overlay');

const closeButton = () => {
    const closeButton = document.createElement('button');
    closeButton.classList.add('close-modal');
    closeButton.innerHTML = '&times;';
    closeButton.addEventListener('click', closeModal);
    return closeButton;
};

const openCertificateModal = (imageUrl) => {
    const imageElement = document.createElement('img');
    imageElement.alt = 'certificate';
    imageElement.className = 'udemy-cert';
    imageElement.src = imageUrl;

    modal.appendChild(closeButton());
    modal.appendChild(imageElement);
    modal.classList.remove('hidden');
    overlay.classList.remove('hidden');
};

const openEmailModal = (message, error = '') => {
    const msg = document.createElement('p');
    msg.innerHTML = `${message} ${error}`;
    modal.appendChild(msg);
    modal.appendChild(closeButton());
    modal.style.width = '70%';
    modal.classList.remove('hidden');
    overlay.classList.remove('hidden');
};

const closeModal = () => {
    modal.innerHTML = '';
    modal.classList.add('hidden');
    overlay.classList.add('hidden');
    modal.style.width = '90%'; // set modal window width back to original
};

const initModals = () => {
    document.querySelector('.certificates').addEventListener('click', (event) => {
        const thumbnail = event.target.closest('.certi-logo');
        if (!thumbnail) return;
        openCertificateModal(thumbnail.children[0].src);
    });

    overlay.addEventListener('click', closeModal);

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
            closeModal();
        }
    });
};

export { closeModal, initModals, openEmailModal };
