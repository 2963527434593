import { initModals } from "./modal";
const certificates = [];

class Certificate {
  #parentElement = document.querySelector(".certificates");
  #title;
  #completionDate;
  #imageUrl;

  constructor(title, completionDate, imageUrl) {
    this.#title = title;
    this.#completionDate = completionDate;
    this.#imageUrl = imageUrl;
  }

  get completionDate() {
    return this.#completionDate;
  }

  #generateMarkup() {
    const formattedDate = this.#completionDate.toLocaleDateString("en-gb", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return `
            <div class="col-xs-12 col-sm-6">
                <div class="certificate-item clearfix">
                    <div class="certi-logo">
                        <img
                            class="certificate"
                            src="${this.#imageUrl}"
                            alt="certificate"
                        />
                    </div>
                    <div class="certi-content">
                        <div class="certi-title">
                            <h4>${this.#title}</h4>
                        </div>
                        <div class="certi-date">
                            <span>${formattedDate}</span>
                        </div>
                    </div>
                </div>
            </div>
        `;
  }

  render() {
    const markup = this.#generateMarkup();
    this.#parentElement.insertAdjacentHTML("beforeend", markup);
  }
}

const populateCertificates = () => {
  certificates.push(
    new Certificate(
      "NGINX Fundamentals: High Performance Servers from Scratch",
      new Date("2021/02/29"),
      new URL("../img/certificates/udemy_cert1.jpeg", import.meta.url)
    ),
    new Certificate(
      "Apache Kafka Series - Learn Apache Kafka for Beginners v2",
      new Date("2019/05/08"),
      new URL("../img/certificates/udemy_cert2.jpeg", import.meta.url)
    ),
    new Certificate(
      "Learn Visual Studio Code (2020)",
      new Date("2021/10/11"),
      new URL("../img/certificates/udemy_cert3.jpeg", import.meta.url)
    ),
    new Certificate(
      "Apache Maven: Beginner to Guru",
      new Date("2021/12/11"),
      new URL("../img/certificates/udemy_cert4.jpeg", import.meta.url)
    ),
    new Certificate(
      "Kotin for Java Developers",
      new Date("2021/12/23"),
      new URL("../img/certificates/udemy_cert5.jpeg", import.meta.url)
    ),
    new Certificate(
      "The Complete JavaScript Course 2022",
      new Date("2022/02/16"),
      new URL("../img/certificates/udemy_cert6.jpeg", import.meta.url)
    ),
    new Certificate(
      "MongoDB - The Complete Developer's Guide 2022",
      new Date("2022/05/28"),
      new URL("../img/certificates/udemy_cert7.jpeg", import.meta.url)
    ),
    new Certificate(
      "Cypress: Web Automation Testing from Zero to Hero",
      new Date("2022/07/05"),
      new URL("../img/certificates/udemy_cert8.jpeg", import.meta.url)
	),
    new Certificate(
      "Vim Masterclass",
      new Date("2022/07/17"),
      new URL("../img/certificates/udemy_cert9.jpeg", import.meta.url)
    ),
    new Certificate(
      "Reactive Programming with Spring Framework 5",
      new Date("2024/01/02"),
      new URL("../img/certificates/udemy_cert10.jpeg", import.meta.url)
    )
  );

  certificates.sort((a, b) => b.completionDate - a.completionDate);
  certificates.forEach((certificate) => certificate.render());
};

function renderCertificates() {
  populateCertificates();
  initModals();
}

export { renderCertificates as default, closeModal };
