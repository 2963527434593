import PerfectScrollbar from 'perfect-scrollbar';
export let scrollbars = [];

export const customScroll = () => {
    const windowWidth = window.innerWidth;
    const animatedSections = document.querySelectorAll('.animated-section');

    if (windowWidth > 1024) {
        animatedSections.forEach((section) => {
            const ps = new PerfectScrollbar(section);
            scrollbars.push(ps);
        });
    } else {
        scrollbars.forEach((scrollbar) => scrollbar.destroy());
    }
};
