import { scrollbars, customScroll } from './scrollbars';
import renderCertificates from './certificate';
import PageTransition from './animation';
import Email from './email';

const printCopyright = () => {
    const date = new Date();
    const year = date.getFullYear();
    const copyright = document.querySelector('.copyrights');
    copyright.textContent = `© ${year} All rights reserved.`;
};

const getTransitionEffectNumber = () => Math.floor(Math.random() * 4);

const loadWindowAndResize = () => {
    window.onload = () => {
        const ptPage = document.querySelector('.animated-sections');
        if (ptPage) {
            new PageTransition(getTransitionEffectNumber());
        }
    };

    window.onresize = () => {
        scrollbars.forEach((scrollbar) => scrollbar.update());
    };
};

printCopyright();
renderCertificates();
customScroll();
loadWindowAndResize();
